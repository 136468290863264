import React, { Fragment, useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Button,
  CustomDatePicker,
  Label,
  TitleText,
  Accordion,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import {
  CustomDatepickerForm,
  InputForm,
  TextareaForm,
} from 'components/molecules/HookForm';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCommitmentFinalizationDetail,
  usePostCommitmentFinalization,
} from 'hooks/commitmentFinalization';
import { IoIosAdd } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

const LINKS = [
  {
    label: 'Daftar Full Proposal',
    path: '/cfp',
  },
  {
    label: 'Finalisasi Komitmen',
    isActive: true,
  },
];

const validationSchema = yup.object({
  // notes: yup.string().required("Hasil Verifikasi wajib diisi"),
});

const FinalizationCommitment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { status, data, isLoading } = useCommitmentFinalizationDetail([
    'commitment-finalization-detail',
    id,
  ]);
  const postCommitmentFinalization = usePostCommitmentFinalization();

  const [details, setDetails] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      principles: [
        {
          name: '',
          commitments: [
            {
              name: '',
              activity: [
                {
                  name: '',
                  docType: '',
                  period: '',
                  date: '',
                  entity: '',
                },
              ],
            },
          ],
        },
      ],
    },
  });

  useEffect(() => {
    if (data?.data && data?.data?.commitments.length > 0) {
      const principles = data.data.commitments.map((principle) => {
        return {
          id: principle.id,
          name: principle.principle,
          commitments: principle.details.map((commitment) => ({
            id: commitment.id,
            name: commitment.commitmentName,
            activity: commitment.actifities.map((activity) => ({
              id: activity.id,
              name: activity.activityName,
              docType: activity.implementation[0].reportType,
              period: activity.implementation[0].period,
              date: moment(activity.implementation[0].date).toDate(),
              entity: activity.implementation[0].pic,
            })),
          })),
        };
      });
      methods.reset({ principles });
    }
  }, [data, methods]);

  const {
    fields: fieldPrinciples,
    append: appendPrinciples,
    remove: removePrinciples,
  } = useFieldArray({
    control: methods.control,
    name: 'principles',
  });

  const onSubmit = (payload) => {
    const reqPayload = {
      idProposal: id,
      commitments: payload.principles.map((principle) => {
        return {
          id: principle.id,
          detailCommitments: principle.commitments.map((commitment) => {
            return {
              id: commitment.id,
              commitment: commitment.name,
              activities: commitment.activity.map((act) => {
                return {
                  id: act.id,
                  activity: act.name,
                  pic: act.entity,
                  date: act.date,
                  reportType: act.docType,
                  period: act.period,
                };
              }),
            };
          }),
        };
      }),
      deleteDetailCommitment: [],
      deleteActivities: [],
    };

    postCommitmentFinalization.mutate(reqPayload, {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('commitment-finalize-submit');
        enqueueSnackbar({
          message: 'Commitment Finalized CFP Submited',
          variant: 'success',
        });
        navigate(`/cfp`);
      },
      onError: (error, variables) => {
        console.log(error, variables);
      },
    });
  };

  return (
    <Fragment>
      <Breadcrumbs items={LINKS} />
      <div className="md:flex block items-center mt-8 mb-6">
        <TitleText className="flex-1">
          Komitmen Perlindungan Lingkungan & Sosial
        </TitleText>
      </div>
      <FormProvider {...methods}>
        <div className="mt-6 space-y-6">
          {fieldPrinciples.length === 0 ? (
            <> No Data To Display</>
          ) : (
            fieldPrinciples.map((principle, iPrinciple) => {
              return (
                <Accordion
                  theme="deep_green"
                  open
                  title={() => (
                    <div className="flex gap-4">
                      <span className="font-semibold text-gray-600">
                        {principle?.name}
                      </span>
                    </div>
                  )}
                >
                  <div className="flex flex-col gap-4">
                    <CommitmentCard iPrinciple={iPrinciple} methods={methods} />
                  </div>
                </Accordion>
              );
            })
          )}
        </div>

        <div className="block md:flex items-center justify-between px-6 py-4 border-[1px] border-gray-200 rounded-lg bg-white mb-10 mt-6">
          <Button
            type="button"
            className="button-secondary-outline"
            onClick={() => navigate('/cfp')}
          >
            Kembali
          </Button>
          <Button
            disabled={isLoading}
            type="button"
            className="button-primary"
            onClick={() => methods.handleSubmit(onSubmit)()}
          >
            Submit
          </Button>
        </div>
      </FormProvider>
    </Fragment>
  );
};

const CommitmentCard = ({ iPrinciple, methods }) => {
  const {
    fields: fieldCommitments,
    append: appendCommitments,
    remove: removeCommitments,
  } = useFieldArray({
    control: methods.control,
    name: `principles[${iPrinciple}].commitments`,
  });

  return (
    <>
      {fieldCommitments.map((commitment, iCommitment) => (
        <Accordion
          theme="deep_green"
          open
          title={() => (
            <div className="flex justify-between items-center gap-4">
              <span className="font-semibold text-gray-600">{`Komitmen ${
                iCommitment + 1
              }`}</span>

              <div className="text-md font-semibold p-1 z-50">
                <Button
                  type="button"
                  theme="error"
                  size="sm"
                  onClick={() => removeCommitments(iCommitment)}
                >
                  <RiDeleteBinLine className="h-4 w-4 text-error-900" />
                </Button>
              </div>
            </div>
          )}
        >
          <div className="mt-1 px-1 space-y-4">
            <div className="space-y-2 flex-1">
              <Label>Komitmen</Label>
              <TextareaForm
                name={`principles.${iPrinciple}.commitments.${iCommitment}.name`}
                rows={5}
                placeholder="Komitmen"
              />
            </div>
            <ActivityCard
              iPrinciple={iPrinciple}
              iCommitment={iCommitment}
              methods={methods}
            />
          </div>
        </Accordion>
      ))}

      <div className="text-right">
        <Button
          type="button"
          className="button-secondary-outline"
          startIcon={<IoIosAdd className="text-gray-700" size={20} />}
          onClick={() => {
            appendCommitments([
              {
                id: null,
                name: '',
                activity: [
                  {
                    id: null,
                    activity: '',
                    date: '',
                    docType: '',
                    period: '',
                    entity: '',
                  },
                ],
              },
            ]);
          }}
        >
          Tambah Komitmen
        </Button>
      </div>
    </>
  );
};

const ActivityCard = ({ iPrinciple, iCommitment, methods }) => {
  const {
    fields: fieldActivities,
    append: appendActivities,
    remove: removeActivities,
  } = useFieldArray({
    control: methods.control,
    name: `principles[${iPrinciple}].commitments[${iCommitment}].activity`,
  });

  return (
    <>
      {fieldActivities.map((activity, iActivity) => (
        <div className="space-y-4 border p-4 rounded border-primary-700">
          <div className="space-y-2 flex-1">
            <Label>Kegiatan</Label>
            <InputForm
              className="w-full"
              name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.name`}
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div className="space-y-2 flex-1">
              <Label>Tata Waktu Pelaksanaan</Label>
              <CustomDatepickerForm
                name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.date`}
                placeholder="dd/mm/yyyy - dd/mm/yyyy"
                onClear={() =>
                  methods.setValue(
                    `principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.date`,
                    ''
                  )
                }
                disabled={false}
              />
            </div>
            <div className="space-y-2 flex-1">
              <Label>Jenis Dokumen Laporan</Label>
              <InputForm
                className="w-full"
                name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.docType`}
              />
            </div>
            <div className="space-y-2 flex-1">
              <Label>Periode Pelaporan</Label>
              <InputForm
                className="w-full"
                name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.period`}
              />
              {/* <CustomDatePicker
              name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.period`}
              placeholder="sebelum pelaksanaan proyek/Bulanan/Triwulan/Tahunan"
              showYearPicker
              dateFormat="yyyy"
            /> */}
            </div>
            <div className="space-y-2 flex-1">
              <Label>Pihak/Entitas yang bertanggung jawab</Label>
              <InputForm
                className="w-full"
                name={`principles.${iPrinciple}.commitments.${iCommitment}.activity.${iActivity}.entity`}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="text-right">
        <Button
          type="button"
          className="button-secondary-outline"
          startIcon={<IoIosAdd className="text-gray-700" size={20} />}
          onClick={() => {
            appendActivities([
              {
                id: null,
                activity: '',
                date: '',
                docType: '',
                period: '',
                entity: '',
              },
            ]);
          }}
        >
          Tambah Activity
        </Button>
      </div>
    </>
  );
};

export default FinalizationCommitment;
