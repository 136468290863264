import axiosInstance from "app/interceptors";
import config from "app/config";

export const getFinalizationDetailCommitment = async (idProposal, signal) => {
  const {data} = await axiosInstance.get(`${config.SAFEGUARD_API_URL}/CommitmentFinalization/${idProposal}`, { signal });
  return data;
}

export const SubmitCommitmentFinalization = async (payload) => {
  const {data} = await axiosInstance.post(`${config.SAFEGUARD_API_URL}/CommitmentFinalization`, payload);
  return data;
}

export const SubmitCommitmentFinalizationSignature = async (payload) => {
  const {data} = await axiosInstance.post(`${config.SAFEGUARD_API_URL}/CommitmentFinalization/signature`, payload);
  return data;
}